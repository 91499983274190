<template>
  <div class="card">
    <h3 class="card-title">Crear DDJJ de cargas de familia para la percepción de asignaciones familiares</h3>
    <FormPercepcionCargasFamilia v-if="user" :user="user" @submitted="save"/>
  </div>
</template>
<script>
import {
  convertCargasFamiliaToFormData
} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionCargasFamiliaForm";
import {mapState} from "vuex";
import {percepcionCargasFamiliaApi} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionCargasFamiliaApi";
import Swal from "sweetalert2";
import NuevaCategoria from "@/modules/categorias/views/NuevaCategoria.vue";
import Modal from "@/components/elements/Modal.vue";
import CrearPercepcionCargasFamiliaHijos
  from "@/modules/percepcionesAsignacionFamiliar/components/CrearPercepcionCargasFamiliaHijos.vue";
import FormPercepcionCargasFamilia
  from "@/modules/percepcionesAsignacionFamiliar/components/FormPercepcionCargasFamilia.vue";

export default {
  name: "CrearPercepcionCargasFamilia",
  components: {FormPercepcionCargasFamilia, CrearPercepcionCargasFamiliaHijos, Modal, NuevaCategoria},
  data() {
    return {
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    save(percepcionCargasFamilia) {
      const formData = convertCargasFamiliaToFormData(percepcionCargasFamilia)

      percepcionCargasFamiliaApi.create(formData).then((response) => {
        if (response.success) {
          Swal.fire("Éxito", "Se guardó correctamente", "success");
          this.$router.push({name: "ListarPercepciones"});
        } else {
          Swal.fire("Error", "No se pudo guardar", "error");
        }
      })
        .catch((error) => {
          Swal.fire(
            "Error",
            `<p>Error de servidor</p><p>${error}</p>`,
            "error"
          );
        });
    }
  }
}
</script>

<style scoped>

</style>
